
import Vue from "vue"
import { getAiApiUrl } from "@evercam/shared/api/aiApi.ts"

export default Vue.extend({
  name: "CopilotAnprThumbnail",
  props: {
    url: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 60,
    },
  },
  computed: {
    thumbnailUrl(): string {
      const url = `${getAiApiUrl()}${this.url
        .replace("grt_", "")
        .replace("_grt", "")}?authorization=${this.token}`

      return this.$nuxt.$imgproxy.get720pResizedImageUrl(url)
    },
  },
})
