
import Vue, { type PropType } from "vue"
import type { ChatMessageAnnotation } from "@evercam/ui"

export default Vue.extend({
  name: "CopilotAnnotation",
  props: {
    annotation: {
      type: Object as PropType<ChatMessageAnnotation>,
      required: true,
    },
  },
})
